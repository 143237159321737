#ReviewMMLIS {
  .mm-business-address-divider {
    display: flex;
    margin: 15px 0px 15px 0px;
    div:first-child,
    div:last-child {
      width: 34%;
    }
    div:nth-child(2) {
      //width: 380px;
      width: 32%;
    }
    .center-text {
      text-align: center;
      font-size: 12px;
      letter-spacing: 1.5px;
      padding: 7px 2px;
      color: #6b748e;
    }
  }
  .mm-business-address-divider-short {
    display: flex;
    margin: 15px 0px 15px 0px;
    div:first-child,
    div:last-child {
      width: 44%;
    }
    div:nth-child(2) {
      //width: 180px;
      width: 12%;
    }
    .center-text {
      text-align: center;
      font-size: 12px;
      letter-spacing: 1.5px;
      padding: 7px 2px;
      color: #6b748e;
    }
  }
  .center-text {
    text-align: center;
  }
}

.return-link {
  font-size: 1.6rem;
}
