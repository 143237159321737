.drp-quick-action-wrapper {
    cursor: pointer;
    margin-left: 20px;

    .icon-quick-actions {
        position: relative;
        border: 0;
        right: 20px;
    }

    .drp-dropdown-content-wrapper {
        cursor: pointer;
        position: absolute;

        &.full-width {
            right: 70px;
        }

        z-index: 100;
        padding: 8px 0;
        background: white;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
        display: flex;
        flex-direction: column;
        width: 254px;
        height: 128px;

        .drp-dropdown-edit,
        .drp-dropdown-delete {
            padding-top: 16px;
            padding-bottom: 16px;
            padding-left: 24px;
            justify-content: flex-start;
            align-items: center;
            display: inline-flex;
            text-align: left;
            color: black;
            font-size: 18px;
            font-family: BrandonText;
            font-weight: 420;
            line-height: 24px;
            word-wrap: break-word;

            &:hover {
                background: #f3f4f6;
            }
        }
    }
}
