#mmlis_contribution_added_alert {
  position: absolute;
  top: 90px;
  right: 0;
  z-index: 100;
}

#mmlis_contribution_added_alert
  .mm-toast-container
  .toast
  .wrapper
  .toast-header,
#mmlis_contribution_added_alert .mm-toast-container .toast {
  border-radius: 10px;
}

#mmlis_contribution_added_alert .mm-toast-container .toast {
  border: 2px #328320 solid;
}

#mmlis_contribution_added_alert {
  position: absolute;
  top: 90px;
  right: 0;
  z-index: 100;
}

#mmlis_contribution_delete_alert
  .mm-toast-container
  .toast
  .wrapper
  .toast-header,
#mmlis_contribution_delete_alert .mm-toast-container .toast {
  border-radius: 10px;
}

#mmlis_contribution_delete_alert .mm-toast-container .toast {
  border: 2px #328320 solid;
}

#mmlis_contribution_delete_alert {
  position: absolute;
  top: 90px;
  right: 0;
  z-index: 100;
}
