@media (min-width: 576px) {
  #AddressVerificationFormModal.modal-dialog {
    max-width: 808px;
    top: 30px;
  }
  :focus { outline: none; }
}

#USPSAddress .card {
  border: 2px solid #002E6B!important;
}

#USPSAddress .usps-error.card {
  border: 2px solid #E21224!important;
}


#USPSAddress .card .modal-address {
  margin-bottom: 10px;
  color: #696969;
}

#USPSAddress .card .modal-address-not-found {
  margin-bottom: 10px;
  color: #E21224;
}

#EnteredAddress .card {
  border: 2px solid transparent;
}

#USPSAddress #useUSPSAddress, #EnteredAddress #useEnteredAddress {
  padding: 5.5px 30px;
  width: 100%;
}

#AddressVerificationFormModal .alert-info {
  border-color: #002E6B;
  border-radius: 0.8rem;
  border-top: 2px solid #002E6B!important;
  border-bottom: 2px solid #002E6B!important;
  border-right: 2px solid #002E6B!important;
}
