.modal-content-container {
  .message {
    color: rgba(0, 0, 0, 1);
    font-size: 2.2rem;
  }

  .left-btn-container {
    display: flex !important;
    justify-content: flex-end !important;

    padding: 20px 0;

    button .no-btn {
      text-align: right;
      font-size: 1.4rem;
      padding: 4px 0px 0px 160px;
    }
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    button {
      margin: 0px;
      position: relative;
      top: 2px;
      color: #555555;
    }
  }

  .modal-table {
    td {
      padding: 1.2rem 0.8rem;
    }
    p {
      margin-bottom: 0 !important;
    }
  }

  .description {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-family: Lato;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;
  }

  .drp-add-btn {
    color: white;
    font-size: 16px;
    font-family: MMSharpSans;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 24px;
    letter-spacing: 0.5px;
    word-wrap: break-word;
  }

  .close-drp-btn {
    color: #004db2;
    font-size: 16px;
    font-family: MMSharpSans;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 24px;
    letter-spacing: 0.5px;
    word-wrap: break-word;
  }

  #add_button {
    padding: 5px;
    float: right;
    font-size: 1.3rem;
  }
}

#common-drp-delete-modal.modal-dialog {
  top: 10%;
}

// #common-drp-delete-modal.modal-dialog .modal-content {
// width: 500px;
// }

@media (min-width: 2200px) {
  .safari-only-wrapper #common-drp-delete-modal {
    max-width: 700px;

    .btn-link {
      padding: 10.8px 0px;
    }
  }
}
