#noficationModal {
    #notificationModalCancel {
        margin-left: 20px;
        color: #3174af;
        cursor: pointer;
    }
    #notificationModalHeading h3 {
        color: black;
        font-weight: 700;
        .close {
            margin-bottom: 0;
        }
    }

    #notificationModalHeading h2 {
        margin-bottom: 0;
    }
}
