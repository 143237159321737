//Divider for each DRP Section (i.e: Comments, Status Details, Etc.)
.drp-section-divider {
    display: flex;
    margin: 25px 0px;
    div:first-child,
    div:last-child {
        width: 47%;
    }
    div:nth-child(2) {
        margin: 0px 10px;
        white-space: nowrap;
        text-transform: uppercase;
    }
    .center-text {
        text-align: center;
        font-size: 12px;
        letter-spacing: 1.5px;
        padding: 7px 2px;
        color: #6b748e;
    }
}
