.new-mon-sanct-list {
    hr {
        width: 100%;
        border: 0.8px #c1c9d5 solid;
    }

    .hr-top {
        margin-bottom: 0px;
    }

    .list-header {
        display: flex;
        width: 100%;
        flex-direction: row;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-family: Lato;
        font-weight: 700;
        line-height: 20px;
        margin-bottom: -13px;
        word-wrap: break-word;
        padding: 16px 0;
        gap: 4px;

        .left {
            display: inherit;
            width: 100%;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            font-family: Lato;
            font-weight: 700;
            line-height: 20px;
            word-wrap: break-word;

            > div:first-child {
                flex:4;
                margin-right: 30px;
            }

            > div:last-child {
                flex:2;
                margin-right: 30px;
            }
        }

        .action {
            flex: 2;
            cursor: pointer;
            margin-right: 20px;
        }
    }

    .rows {
        .rows-wrapper {
            width: 100%;
            min-height: 60px;
            display: flex;
            height: auto;
            flex-direction: column;

            .drp-list-row {
                display: flex;
                width: 100%;
                color: rgba(0, 0, 0, 0.85);
                font-size: 14px;
                font-family: Lato;
                font-weight: 400;
                line-height: 16px;
                word-wrap: break-word;
                align-items: center;

                .action {
                    cursor: pointer;
                    margin-right: 5px;
                    position: relative;
                    top: 2px;
                    flex: 2;
                }

                .left {
                    display: inherit;
                    width: 100%;
                    color: rgba(0, 0, 0, 0.85);
                    font-size: 14px;
                    font-family: Lato;
                    font-weight: 400;
                    line-height: 16px;
                    word-wrap: break-word;

                    > div:first-child {
                        flex:4;
                        margin-right: 30px;
                    }
        
                    > div:last-child {
                        flex:2;
                        margin-right: 30px;
                    }
                }
            }
        }
    }
}
