#residentialaddress {
    #residential-address-instructions {
        .col-12 {
            padding-top: 15px;
            #res_addr_po_box_text {
                font-size: 16px;
            }
        }
    }
}
