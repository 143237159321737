#brokerdealerform {
    h3 {
        padding-bottom: 5px;
        padding-top: 10px;
    }

    #brokers {
        .row:last-child {
            padding-bottom: 10px;
            padding-top: 10px;
        }
    }
    #completed {
        .row {
            padding-bottom: 10px;
        }
    }
    #actions {
        text-align: right;
        padding-top: 30px;

        #broker_back {
            margin-right: 25px;
            color: rgb(49, 116, 175);
            cursor: pointer;
        }
    }

    .custom-control-label {
        color: #555;
    }

    #broker_forgot_text a {
        text-decoration: underline;
    }
}
