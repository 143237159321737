.m-logo {
  height: 40vmin;
  pointer-events: none;
}
.blueprint-logo {
  height: 5rem;
}

.dropdown-toggle::after {
  float: right;
  margin-top: 10px;
  height: 14px;
}

div.dropdown button {
  text-align: left;
}

[class*="mm-dots-loading"][class*="-md"] {
  margin: 0;
}

.dropdown-menu {
  border: 0.1rem solid white;
  background-clip: border-box;
}

.dropdown-menu::-webkit-scrollbar {
  background-color: white;
}

.dropdown-menu.show {
  border: white;
}

.left-side-lp-instructions ul li {
  margin-bottom: 1.2rem;
}

.mm-footer {
  margin-bottom: 50px;
}

.mm-footer a.btn-link {
  font-weight: 500;
}

.float-right {
  float: right;
}

#business #state-business:disabled,
#account_type_aff:disabled {
  padding: 0.8rem 1.6rem;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.33;
  background-clip: padding-box;
  color: #c1c9d5;
  border: 1px solid rgb(107, 116, 142, 0.5);
  border-radius: 0.4rem;
  opacity: 1;
}

#account_type_aff {
  text-transform: lowercase;
}

#account_type_aff::first-letter {
  text-transform: uppercase;
}

#business .form-control[readonly],
#business .form-control:disabled,
#affiliated .form-control[readonly],
#affiliated .form-control:disabled,
#personal_email.form-control[readonly],
#personal_email.form-control:disabled,
#ssn.form-control[readonly],
#ssn.form-control:disabled,
#dob.form-control[readonly],
#dob.form-control:disabled,
#middle_name.form-control[readonly],
#middle_name.form-control:disabled {
  color: #c1c9d5;
  background-color: white;
  border: 1px solid rgb(107, 116, 142, 0.5);
}

.disable-label {
  color: #000000;
  opacity: 0.5;
}

#business .form-control:disabled::placeholder {
  color: #c1c9d5;
  background-color: white;
}

#ShowContractModal {
  min-width: 668px;
}

a.btn-link:active,
a.btn-link:focus,
span.btn-link:active,
span.btn-link:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}

// Lines 115-138:
// For Sent To Candidate/Invitation Sent Landing Page Checklist
#IndividualBrokerChecklist li,
#CareerAgentChecklist li {
  display: inline-flex;
  margin-bottom: 1.2rem;
}
#IndividualBrokerChecklist li div:first-child,
#CareerAgentChecklist li div:first-child {
  padding-right: 5px;
}
#CareerAgentChecklist ul li {
  margin-bottom: 0.5rem;
  display: list-item;
}
#CareerAgentChecklist li {
  margin-bottom: 1.5rem;
}
#CareerAgentChecklist ul li::marker {
  font-size: 10px;
}
#CareerAgentChecklist ul {
  list-style: outside;
  margin-top: 0.5rem;
}

.tab-loader {
  margin: auto;
  width: 15%;
  padding: 10px;
}

@font-face {
  font-family: MMSharpNormal;
  font-style: normal;
  src: local("MM Sharp Sans"),
    url(../fonts/MMSharpSans-Black.otf) format("truetype");
}

@font-face {
  font-family: MMSharpBold;
  src: local("MM Sharp Sans Bold"),
    url(../fonts/MMSharpSans-Bold.otf) format("truetype");
}

@font-face {
  font-family: MMSharpLight;
  src: local("MM Sharp Sans Light"),
    url(../fonts/MMSharpSans-Light.otf) format("truetype");
}

@font-face {
  font-family: MMSharpMedium;
  src: local("MM Sharp Sans Medium"),
    url(../fonts/MMSharpSans-Medium.otf) format("truetype");
}

@font-face {
  font-family: MMSharpSans;
  src: local("MM Sharp Sans SemiBold"),
    url(../fonts/MMSharpSans-SemiBold.otf) format("truetype");
}

@font-face {
  font-family: MMSharpThin;
  src: local("MM Sharp Sans Thin"),
    url(../fonts/MMSharpSans-Thin.otf) format("truetype");
}

/* PADDINGS and MARGINS */
$spacing-values: 0.5rem, 1rem, 1.5rem, 2rem, 2.5rem, 3rem, 3.5rem, 4rem, 4.5rem,
  5rem;

@for $i from 1 through length($spacing-values) {
  .pr-#{$i} {
    padding-right: nth($spacing-values, $i);
  }

  .pl-#{$i} {
    padding-left: nth($spacing-values, $i);
  }

  .mr-#{$i} {
    margin-right: nth($spacing-values, $i);
  }

  .ml-#{$i} {
    margin-left: nth($spacing-values, $i);
  }

  .mt-#{$i} {
    margin-top: nth($spacing-values, $i);
  }

  .mb-#{$i} {
    margin-bottom: nth($spacing-values, $i);
  }

  .gap-#{$i} {
    gap: nth($spacing-values, $i);
  }
}

.alert-danger--compact {
  border-color: #a72d1a;
}
.alert-border {
  border-color: #a72d1a !important;
}
.alert[class*="--compact"] {
  color: #a72d1a !important;
}

.table-responsive {
  overflow-wrap: anywhere;
}

.review-form {
  .actions {
    > div:first-child {
      float: left;
      margin-right: 20px;
    }
    div:nth-child(2) {
      float: right;
    }
    div:nth-child(3) {
      position: absolute;
      right: 0;
      padding: 5px;
    }
  }
}

.candidate-info {
  .link-desc-wrapper {
    margin-bottom: 19px;
  }

  .adv-op-hub-mail {
    margin: 0px 1px 4px 1px;
    padding: 0;
    font-size: 1.6rem;
    text-decoration: underline;
    font-weight: 400;
  }
}