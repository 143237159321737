#ReviewCivilJudicialDRP {
    #sanctionDetailsSection {
        .injunction .injunction-header,
        .monetary-related-sanction .monetary-related-sanction-header {
            margin-bottom: 15px;
        }
        .injunction .injunction-body,
        .monetary-related-sanction .monetary-related-sanction-body {
            margin-left: 25px;
            .row .col-6:nth-child(2) {
                padding-left: 2px;
            }
        }
    }
}
