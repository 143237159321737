#startAppNavCAS,
#startAppNavBroker {
  margin-top: 16%;

  .progress-bar-section {
    #startAppNavProgressHeader {
      padding-bottom: 12px;
      color: black;
      width: 100%;
    }
    #startAppNavProgressBar {
      padding-right: 5px;
    }
    #startAppNavProgressBarLabel {
      padding-left: 0;
      padding-right: 0;
      #progressComplete {
        vertical-align: top;
        line-height: 1;
      }
    }
    .progress {
      height: 0.4rem !important;
    }

    .progress-bar {
      background-color: #004db2 !important;
    }
  }

  .nav-items .row {
    margin-top: 15px;
    [id*="check"],
    [id*="summary"] {
      float: right;
    }
    .nav-summary-text {
      font-weight: 400;
      font-size: 14px;
      text-align: right;
      color: rgb(0, 0, 0, 0.85);
      margin-top: 2px;
    }
  }

  [id*="nav"] strong {
    color: black;
  }

  .nav-name {
    max-width: 85%;
    float: left;
  }

  //ReviewAndSign Button styling
  #reviewAndSignBtn {
    width: 100%;
    margin-top: 25px;
  }

  //EXIT Button styling
  .btn-link-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn-link {
      font-size: 16px;
      margin-top: 10px;
    }
  }
}
.nav-title {
  padding-right: 5%;
}

.start-app-nav-border {
  box-shadow: 4px 0px 2px -2px rgb(0 0 0 / 13%);
}

//Styling for Only CAS Nav
#startAppNavCAS {
  .mm-collapse {
    display: block;
    border-top: 0 !important;
    border-bottom: 0 !important;
    padding: 0 !important;
  }

  .mm-collapse .btn-collapse:focus {
    outline: none;
  }

  h3 {
    color: #002f6c;
    width: 85%;
  }

  #employmentHistoryAccordion,
  #ccbAccordion {
    margin-top: 15px;

    hr {
      margin-bottom: 0;
      width: 15%;
      margin-left: 15px;
    }

    .indicator {
      margin-left: 6px;
      position: relative;
      right: 5px;
    }
  }
  #empAccordion {
    margin-top: 15px;

    hr {
      margin-bottom: 0;
      width: 15%;
      margin-left: 15px;
    }

    .indicator {
      margin-left: 6px;
      position: relative;
      right: 5px;
    }
  }
}

//Styling for only Broker Nav
#startAppNavBroker {
}
