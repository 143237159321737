.judgment-lien-court-details-form-wrapper {
    .field-label,
    .info-label {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
    }
    #courtName,
    #courtLocation,
    #docketOrCaseNumber,
    .form-custom-control-wrapper {
        width: 60%;
    }

    #courtLocationLabel span {
        text-decoration: underline;
    }

    .form-control,
    .dropdown-toggle,
    .input-group-text {
        border-width: 1.5px !important;
    }
    .date-wrapper {
        margin-bottom: 10px;
    }
    .date-explanation-container {
        width: 80%;
    }
    .form-control .is-invalid {
        border-color: #e21224 !important;
        color: #e21224 !important;
    }
    .is-invalid #arbStatusOption,
    .input-group.is-invalid > #customerDamageAmount,
    .input-group.is-invalid .input-group-text,
    .input-group.is-invalid input:focus {
        border-color: #e21224 !important;
        color: #e21224 !important;
        .show {
            display: block !important;
        }
    }
    .input-group {
        margin-bottom: 0px !important;
    }

    #courtActionTypeOtherText,
    #courtActionTypeOtherTextError {
        margin-left: 25px;
    }

    .form-custom-control-wrapper label {
        font-weight: 500;
        color: black;
    }
    .backbutton-drp-judgment-lien {
        padding-top: 40px;
        padding-bottom: 20px;

        .buttons {
            span {
                margin-right: 25px;
                color: #3174af;
                cursor: pointer;
            }
        }
    }
}
