.civ-jud-sanc-details-form-wrapper {
    .reg-action-sanc-details-other-container {
        width: 100%;

        .other-label {
            color: black;
            font-size: 16px;
            font-family: Lato;
            font-weight: 400;
            line-height: 24px;
            word-wrap: break-word;
        }
    }

    .add-new-charge-desctiption-label {
        color: black;
        padding-left: 1.6rem;
        width: 100%;
        font-family: Lato;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: left;
        margin-bottom: 5px;
        margin-top: 24px;
    }

    .sanc-list-container {
        display: flex;
        flex-direction: column;
    }

    .comments-label {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
    }

    .crim-comments-container {
        margin-top: -24px;
        width: 100%;
    }

    .add-new-charge-label {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
        margin-bottom: 10px;
    }

    .new-charges-list {
        max-width: 440px;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    .add-drp-charge-btn-wrapper {
        .add-drp-charge-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 200px;
            padding: 8px 16px 8px 16px;
            border-radius: 100px;
            gap: 8px;
            border-color: #004db2;

            .btn-content {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .text-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                strong,
                span {
                    font-family: MMSharpSans;
                    color: #004db2;
                    font-weight: 600;
                    text-transform: none;
                }

                strong {
                    width: max-content;
                }

                span {
                    display: flex;
                    align-items: center;
                    margin-right: 5px !important;
                }
            }

            &:hover {
                .btn-content {
                    span {
                        svg {
                            g {
                                fill: white;
                            }
                        }
                    }

                    strong {
                        color: white;
                    }
                }
            }
        }
    }

    .custom-hr {
        margin-top: 25px;
        height: 0px;
        width: calc(100% - 25px);
        border: 0.8px #c1c9d5 solid;
        margin-left: 0;
        margin-bottom: 5px;
    }

    .backbutton-drp-crim {
        padding-top: 40px; 
        padding-bottom: 20px;

        .buttons {
            span {
                margin-right: 25px;
                color: #3174af;
                cursor: pointer;
            }
        }
    }
}
