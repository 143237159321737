#psr_added_alert {
    position: absolute;
    top: 90px;
    right: 0;
    z-index: 100;
}

#psr_added_alert .mm-toast-container .toast .wrapper .toast-header,
#psr_added_alert .mm-toast-container .toast {
    border-radius: 10px;
}

#psr_added_alert .mm-toast-container .toast{
    border: 2px #328320 solid;
}