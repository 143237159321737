#ReviewRegulatoryActionDRP {
    .sanction-body,
    .requalification-body,
    .monetary-sanction-body {
        margin-left: 20px;
        .row .col-6:first-child {
            max-width: 49%;
        }
    }
}
