#ErrorPage {
    #ErrorBody {
        img {
            width: 480px;
            height: 480px;
        }
        h2 {
            text-align: center;
        }
    }
}
