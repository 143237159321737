.customer-complaint-form-wrapper {
    .arbitration-civil-litigation-wrapper,
    .not-pending-wrapper {
        margin-left: 50px;
    }

    .customer-complaint-label {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
        margin-top: 10px;
        letter-spacing: 0.01px;
    }

    .form-control,
    textarea,
    input[type="text"],
    .input-group-text,
    .dropdown-toggle {
        border-width: 1.5px !important;
    }

    .form-control .is-invalid {
        border-color: #e21224 !important;
        color: #e21224 !important;
    }
    .input-group.is-invalid > #custComplaintContribAmount,
    .input-group.is-invalid > #custComplaintMonetaryComp,
    .input-group.is-invalid .input-group-text,
    .input-group.is-invalid input:focus {
        border-color: #e21224 !important;
        color: #e21224 !important;
    }
    .is-invalid #custComplaintStatusOption {
        border-color: #e21224 !important;
        color: rgba(0, 0, 0, 0.4) !important;
    }

    #custComplaintFilingDate,
    #custComplaintDateReceived,
    #custComplaintStatusDate {
        width: 50%;
    }
    .court-action-other {
        width: 360px;
        margin-left: 50px;
    }

    .drp-radio-input-wrapper div:last-child {
        margin-bottom: 0px;
    }
    .date-wrapper {
        margin-bottom: 10px;
    }
    .custom-radio {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.01px;
        text-align: left;
        margin-left: 25px;
    }
    .status-placeholder button {
        color: rgba(0, 0, 0, 0.4) !important;
    }

    .backbutton-drp-customer-complaint {
        padding-top: 40px;
        padding-bottom: 20px;

        .buttons {
            span {
                margin-right: 25px;
                color: #3174af;
                cursor: pointer;
            }
        }
    }
}
