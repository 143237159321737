#homepage {
  .greeting-section,
  #applicantGreeting {
    margin-bottom: 15px;
  }

  .current-indicator {
    //font-family: 'Brandon Text';
    //font-style: normal;
    font-weight: 420;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #327320;
  }
  .step-text {
    //font-family: 'Brandon Text';
    //font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgb(0, 0, 0, 0.85);
  }

  /* Tooltip container */
  .tooltipContainer {
    position: relative;
    display: inline-block;
    padding-left: 10px;
  }

  /* Tooltip text */
  .tooltipContainer .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: #555;
    color: #ffffff;
    text-align: left;
    padding: 10px;
    border-radius: 6px;
    bottom: 100%;
    left: 50%;
    margin-left: -150px;
    font-size: 14px;
    font-weight: 50px;
    margin-bottom: 5px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltipContainer:hover .tooltiptext {
    visibility: visible;
  }

  .page-link {
    border: 1px black !important;
  }
}
