.bond-organization-information-form-wrapper {
    .field-label,
    .info-label {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
    }
    .info-label {
        margin-bottom: 7px;
    }

    .form-control {
        border-width: 1.5px;
    }

    .backbutton-drp-bond {
        padding-top: 40px;
        padding-bottom: 20px;

        .buttons {
            span {
                margin-right: 25px;
                color: #3174af;
                cursor: pointer;
            }
        }
    }
}
