.home-page-prior-apps .container {
    color: black;
    border: 0px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 1px 1px 1px 0px lightgray;
    padding: 10px 0px 20px 15px;
    margin: 10px 5px 5px 0px;
    .row {
        width: 100%;
        h3 {
            font-size: 21px;
            font-weight: 600;
            margin-bottom: 5px !important;
        }
        .col-4 {
            padding-right: 0;
        }
        .col-7 {
            &.regular-layout,
            &.broker-corporation-layout,
            &.archive-decline-broker-corp-layout,
            &.archive-decline-regular-layout {
                display: flex;
                .archive-message div:nth-child(2) {
                    font-size: 14px;
                }
            }
            &.archive-decline-broker-corp-layout .archive-message {
                flex: 2;
                div {
                    width: 90%;
                }
            }
            .agency-number,
            .effective-date,
            .corporation-information,
            .archive-message {
                display: inline-block;
                width: auto;
                div:first-child {
                    margin-top: 5px !important;
                }
            }
            .agency-number {
                flex: 0.75;
            }
            .effective-date {
                flex: 1.25;
            }
            .corporation-information {
                flex: 1;
                word-break: break-word;
            }
        }
        .col-1 {
            display: flex;
            margin-left: auto;

            .icon-quick-actions {
                margin-top: 5px;
                button {
                    opacity: 0;
                    min-width: 25px;
                    padding: 0;
                }
                .dropdown-menu {
                    min-width: 280px;
                    min-height: 250px;
                    max-height: 500rem !important;
                    overflow: hidden;
                }
                &.mc-quick-actions .dropdown-menu {
                    min-height: 0px !important;
                }
            }
        }
    }
    .review-dot {
        height: 8px;
        min-width: 8px;
        background-color: red;
        border-radius: 50%;
        display: inline-block;
        margin: 2px 6px -2px 0px;
    }

    .badge.badge-indicator {
        width: Fixed (8px) px;
        height: Fixed (8px) px;
        gap: 0px;
        border-radius: 100px 0px 0px 0px;
        border: 1px 0px 0px 0px;
        opacity: 0px;
        background: #e21224;
        border: 1px solid #ffffff;
        box-shadow: 0px 1px 4px 0px #0000001a;
    }
}
