.drp-summary-container {
    .drp-error {
        display: flex;
        justify-content: flex-end;
        color: #a72d1a;
        width: 85%;
        padding-top: 1rem;

        > span {
            font-size: 14px;
            font-family: Lato;
            font-weight: 400;
            line-height: 16px;
            word-wrap: break-word;
            margin-left: 5px;
        }
    }

    .content {
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0;
        [id*="drpCheckbox"] {
            white-space: nowrap;
            margin-bottom: 0;
            width: 100px !important;
        }

        .left {
            height: 100% !important;
            margin-right: 36px;
            padding: 0.6rem 0;
            word-wrap: break-word;
            min-width: 90px;
            margin-right: 0;

            .drp {
                margin-right: 0;
            }
            label {
                font-family: Lato;
                font-weight: 700;
                line-height: 24px;
                color: black;
                font-size: 16px;
                letter-spacing: 0.5px;
            }
        }

        .card-checkbox {
            padding-top: 5px;
            width: 30px;
        }

        .right {
            display: flex;
            flex-direction: column;
            width: 100%;

            .show-question {
                .header {
                    display: flex;
                    justify-content: space-between;
                    margin: 0 0 22px -2px;

                    p {
                        width: 92px;
                        text-decoration: underline;
                        font-weight: 500;
                        line-height: 1.43;
                        font-size: 1.4rem;
                        text-align: center;
                        font-family: "BrandonText", Helvetica, Arial, sans-serif;
                        letter-spacing: inherit;
                    }

                    &.drp {
                        display: flex;
                        justify-content: flex-end;
                    }
                }

                .questions {
                    margin-bottom: 32px;
                    color: black;
                    font-size: 16px;
                    font-family: Lato;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                    word-wrap: break-word;

                    &.drp {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        left: -90px;
                    }

                    .sub {
                        margin-left: 30px;

                        &.indent {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }

    hr {
        width: 100%;
        height: 0px;
        border: 0.8px #c1c9d5 solid;
    }
}
