@media (min-width: 576px) {
  #PersonalSalesRecordModal.modal-dialog {
    max-width: 600px;
    top: 30px;
  }
  :focus { outline: none; }
}

.psr-top-cell {
  vertical-align: top;
  padding-top: 5px;
}

.psr-error-padding {
  margin-top: -5px !important;
  margin-bottom: 10px !important;
}