.bankruptcy-form-wrapper {
  .action-placeholder button {
    color: rgba(0, 0, 0, 0.4) !important;
  }
  .field-label,
  .info-label,
  .custom-control {
    color: black;
    font-size: 16px;
    font-family: Lato;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;
  }
  .custom-control-label {
    font-weight: 500 !important;
  }
  .drp-radio-input-wrapper {
    margin-top: 10px;
    margin-left: 20px;
    width: 40%;
    .dropdown-toggle {
      text-transform: none !important;
      margin-left: 25px;
      margin-bottom: 10px;
    }
    .dropdown-toggle::after {
      top: 5px;
    }

    .dropdownmenu {
      position: absolute;
      display: inline-block;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #ffffff;
      min-width: 160px;
      overflow: auto;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 999;
      right: 60px;
    }

    .dropdown-content span {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      font-weight: 500;
      cursor: pointer;
    }

    .dropdownmenu span:hover {
      background-color: #f3f4f6;
    }
  }

  .input-group {
    margin-bottom: 0;
  }

  .form-control {
    border-width: 1.5px;
    border: 1px solid #6b748e;
    border-radius: 0.4rem;
    margin-bottom: 10px;
  }

  .input-group .input-group-prepend .btn:first-child,
  .input-group .input-group-prepend .mm-back-link:first-child,
  .input-group .input-group-prepend .input-group-text:first-child {
    border-width: 1.5px;
    border: 1px solid #6b748e;
    border-radius: 0.4rem;
  }

  .input-group .input-group-prepend .btn:first-child #compOriginalAmountOwed,
  .input-group
    .input-group-prepend
    .mm-back-link:first-child
    #compOriginalAmountOwed,
  .input-group
    .input-group-prepend
    .input-group-text:first-child
    #compOriginalAmountOwed {
    border-width: 1.5px;
  }

  hr {
    margin-bottom: 4rem;
  }

  .backbutton-drp-bankruptcy {
    padding-top: 40px;
    padding-bottom: 20px;

    .buttons {
      span {
        margin-right: 25px;
        color: #3174af;
        cursor: pointer;
      }
    }
  }
}
