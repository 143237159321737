.reg-action-status-det-form-wrapper {
    .add-new-charge-desctiption-label {
        color: black;
        padding-left: 1.6rem;
        width: 100%;
        font-family: Lato;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: left;
        margin-bottom: 5px;
        margin-top: 24px;
    }

    .add-new-charge-label {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
        margin-bottom: 10px;
    }

    .event-status,
    .event-status-date-exp {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
    }

    .event-status {
        margin-top: 24px;
    }

    .event-status-date-wrapper {
        strong {
            font-family: Lato;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.01px;
            text-align: left;
        }

        .event-status-date-input {
            width: 275px;
        }
    }

    .event-status-date-explanation-container {
        width: 100%;
        padding-right: 30px;
        margin-left: 28px;
    }

    .new-charges-list {
        max-width: 440px;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    .sentence-hr {
        padding-top: 25px;
        padding-bottom: 25px;
        display: flex;
        max-width: 100%;
        font-family: Lato;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 2px;
        text-align: center;
        margin-top: -15px;

        &.expl {
            padding-top: 15px;
        }

        .eyebrow-text-wrapper {
            strong {
                width: max-content;
            }
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 10px 0 10px;
        }

        > hr {
            width: 100%;
            height: 0px;
            border: 0.8px #c1c9d5 solid;
            margin-left: 0;
        }
    }

    .court-name,
    .court-location,
    .docker-case,
    .court-action {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
    }

    .court-action-other {
        width: 360px;
        margin-left: 50px;
    }

    .custom-radio {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.01px;
        text-align: left;
        margin-left: 25px;
    }

    .custom-hr {
        margin-top: 50px;
        max-width: 762px;
        height: 0px;
        border: 0.8px #c1c9d5 solid;
        margin-left: 0;
        margin-bottom: 5px;
    }

    .add-drp-charge-btn-wrapper {
        .add-drp-charge-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 200px;
            padding: 8px 16px 8px 16px;
            border-radius: 100px;
            gap: 8px;

            .btn-content {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                strong,
                span {
                    font-family: MMSharpSans;
                    color: #004db2;
                    font-weight: 600;
                    text-transform: none;
                }

                strong {
                    width: max-content;
                }

                span {
                    display: flex;
                    align-items: center;
                    margin-right: 5px !important;
                }
            }

            &:hover {
                .btn-content {
                    span {
                        svg {
                            g {
                                fill: white;
                            }
                        }
                    }

                    strong {
                        color: white;
                    }
                }
            }
        }
    }

    input::placeholder {
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.01px;
        text-align: left;
    }

    .sentence-hr {
        display: flex;
        max-width: 100%;
        font-family: Lato;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 2px;
        text-align: center;
        margin-top: -15px;

        .eyebrow-text-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 10px 0 10px;
        }

        > hr {
            width: 100%;
            height: 0px;
            border: 0.8px #c1c9d5 solid;
            margin-left: 0;
        }
    }

    .custom-hr {
        margin-top: 24px;
        max-width: 762px;
        height: 0px;
        border: 0.8px #c1c9d5 solid;
        margin-left: 0;
        margin-bottom: 5px;
    }

    .event-status {
        margin-top: 24px;
    }

    .event-status-date-wrapper {
        strong {
            font-family: Lato;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.01px;
            text-align: left;
        }

        .event-status-date-input {
            width: 275px;
        }
    }

    .event-status-date-exp {
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.01px;
        text-align: left;
    }

    .event-status-date-explanation-container {
        width: 100%;
        margin-left: 45px;

        textarea {
            min-height: 80px;
        }
    }

    .amount-paid-input-group {
        width: 275px;
    }

    .amount-error {
        color: #e21224;
        border-color: #e21224;

        & > span {
            color: #e21224;
            border-color: #e21224;
        }
    }

    #amount-paid-dollar-sign-id {
        &.is-invalid {
            border-color: red;
        }
    }

    .custom-radio {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.01px;
        text-align: left;
        margin-left: 25px;
    }

    .event-status-date-radio-group {
        margin-top: 10px;
    }

    .inv-business {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
    }

    .date-of-disp {
        margin-top: -5px;
    }

    .backbutton-drp-crim {
        padding-top: 40px;
        padding-bottom: 20px;

        .buttons {
            span {
                margin-right: 25px;
                color: #3174af;
                cursor: pointer;
            }
        }
    }
}
