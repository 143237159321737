.custom-tooltip-component-wrapper {
    .tooltipContainer {
        position: relative;
        display: inline-block;
        padding-left: 10px;
    }

    /* Tooltip text */
    .tooltipContainer .tooltiptext {
        visibility: hidden;
        width: 300px;
        background-color: #555;
        color: #ffffff;
        text-align: left;
        padding: 10px;
        border-radius: 6px;
        bottom: 100%;
        left: 50%;
        margin-left: -150px;
        font-size: 14px;
        font-weight: 50px;
        margin-bottom: 5px;

        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
    }

    /* Show the tooltip text when you mouse over the tooltip container */
    .tooltipContainer:hover .tooltiptext {
        visibility: visible;
    }
}
