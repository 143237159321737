#reviewsignform1 {
    .data {
        color: #555;
        overflow-wrap: break-word;
    }
    #review_direct_deposit {
        .margin-bottom-20px {
            margin-bottom: 20px;
        }
    }
    #backbtn {
        margin-right: 25px;
        color: #3174af;
        cursor: pointer;
    }
    #nextbtn {
        margin-right: 10px;
    }

    .actions {
        text-align: right;
        padding-top: 30px;
    }
}
#reviewsignheader {
    .row:last-child {
        margin-bottom: 40px;
    }
}
#ReviewAndSign2 {
    #reviewSignInjectedText {
        br {
            display: block;
            margin: 15px 0;
            content: " ";
        }
        .consumerRightsLink {
            color: #004db2 !important;
            font-weight: 500;
            text-decoration: underline;
        }
    }
}

#reviewsignformCAS {
    #headerText {
        font-size: 40px !important;
        font-weight: 500;
        color: black;
        line-height: 50px;
        margin: 20px;
    }

    #reviewCompletedText {
        padding-top: 15px;
        padding-bottom: 30px;
    }
    #reviewCheckBox {
        padding: 10px;
        text-align: center;
    }
    #reviewCheckBox {
        filter: hue-rotate(-80deg) brightness(90%);
    }
    #message-box {
        background-color: #f6f8f4;
        padding: 2px;
        margin-left: 2px;
        text-align: left;
    }
    #eyebrow-text {
        padding-top: 15px;
        padding-bottom: 10px;
    }
    #review-edit-btn {
        margin-right: 25px;
        color: #3174af;
        cursor: pointer;
        padding: 5px;
    }
    #backbtn {
        margin-right: 25px;
        color: #3174af;
        cursor: pointer;
    }
    #nextbtn {
        margin-right: 10px;
    }
}
