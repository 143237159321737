.criminal-disclosure-org-details-form-wrapper {
    .crim-discl-drp-summary {
        padding-top: 20px;

        > .drp-main-card {
            width: 100%;
        }
    }

    .checkbox-container {
        border: 1px solid #ddd;
        width: 600px;
        height: 300px;
        overflow-y: scroll;
        border-radius: 5px;
    }

    .org-desctiption-label {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 700;
        width: 100%;
        line-height: 24px;
        letter-spacing: 0.5px;
        word-wrap: break-word;
        margin-bottom: 24px;
        padding-left: 1.6rem;
    }

    .org-name,
    .inv-business,
    .pos-title-rel {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
        letter-spacing: 0.01px;
    }

    .drp-radio-input-wrapper {
        margin-left: 30px;
    }

    .inv-business {
        margin-top: 24px;
    }

    .backbutton-drp-crim {
        padding-top: 40px;
        padding-bottom: 20px;

        .buttons {
            span {
                margin-right: 25px;
                color: #3174af;
                cursor: pointer;
            }
        }
    }
}
