#residentialHistoryForm {
    #initialAddHistory {
        color: rgb(49, 116, 175);
        cursor: pointer;
        strong {
            padding-left: 5px;
        }
    }

    hr {
        color: #c1c9d5;
    }
    .row:not(:first-child) {
        padding-top: 15px;
    }

    #residentialHistoryHeader {
        margin-top: 0;
    }

    #residentialHistoryInstruction2 {
        font-size: 16px;
        color: #555;
    }

    .residential-history-grid {
        margin-top: 25px;
        .timeline-li {
            height: auto;
        }
    }

    .mm-vertical-timeline {
        margin-top: 20px;
        margin-left: 11px;
        li {
            margin-bottom: 0;
        }

        li::before {
            display: none;
        }
        .custom-table-timeline-dot {
            position: absolute;
            display: flex;
            height: 10px;
            width: 10px;
            border-radius: 50%;
            left: 22px;
        }
        li.active .custom-table-timeline-dot {
            background-color: #c1c9d5 !important;
        }
        li.complete .custom-table-timeline-dot {
            background-color: #002f6c !important;
        }
    }
    .menu-item-hover:hover {
        background-color: #f3f4f6;
    }
    .timeline-date-text {
        color: #555;
    }
    .toggle-page-actions {
        margin-top: 50px;
        display: inline-flex;
        float: right;
        .back-btn-wrapper {
            margin-right: 20px;
            margin-top: 5px;
            color: rgb(49, 116, 175);
            cursor: pointer;
        }
    }
    .add-residential-btn {
        color: rgb(49, 116, 175);
        cursor: pointer;
        strong {
            padding-left: 5px;
        }
    }

    .column-border-top-wrapper {
        display: inline-flex;
        position: absolute;
        width: 93.75%;
        .column-border-top-pad {
            margin-left: 10px;
            width: 90px;
        }
        .column-border-top {
            border-top: 1px solid rgb(193, 201, 213);
            width: 100%;
        }
    }
    .custom-table-row {
        display: inline-flex;
        width: 100%;
        align-items: center;
        font-size: 14px;

        .custom-table-date-column {
            margin: 5px 0 5px 5px;
            width: 105px;
            word-break: break-word;
            display: flex;
            align-items: center;
        }
        .custom-table-residence-column {
            width: 100%;
            margin-left: 20px;
            word-break: break-word;
            display: flex;
            align-items: center;
            padding: 10px 0px;
        }
        .residential-history-address {
            float: left;
            width: 90%;
            margin-right: 10px;
        }
        .address-quick-action-wrapper {
            float: right;
            .icon-quick-actions {
                border: 0;
            }
            #mnuLinkReview,
            #mnuLinkDelete {
                text-align: left;
            }
        }
    }
}
