.toast-wrapper {
    #ccb_alert {
        position: absolute;
        top: 90px;
        right: 0;
        z-index: 100;
    }
    
    #ccb_alert .mm-toast-container .toast .wrapper .toast-header,
    #ccb_alert .mm-toast-container .toast {
        border-radius: 10px;
    }
    
    #ccb_alert .mm-toast-container .toast{
        border: 2px #328320 solid;
    }
}
