@media (min-width: 576px) {
    #USPSValidationModal.modal-dialog {
        max-width: 700px;
        top: 30px;
    }
    :focus {
        outline: none;
    }
}

#USPSValidationModal.modal-dialog .modal-content {
    border-radius: 12px;
}
@media (min-width: 576px) {
    #addAddressModal.modal-dialog {
        max-width: 650px;
        top: 30px;
        border-radius: 5px;
    }
    :focus {
        outline: none;
    }
    #addAddressModal.modal-dialog .modal-content {
        border-radius: 15px;
    }

    #addAddressModal #state:disabled {
        background-color: #fff;
        border: 0.1rem solid #6b748e;
        border-radius: 0.4rem;
        color: #555;
        text-transform: capitalize;
        padding: 0.8rem 1.6rem;
        height: 4rem;
    }

    #addAddressModal #state:disabled::after {
        display: none;
    }
    #addAddressModal .btn-secondary.dropdown-toggle:not(:disabled):not(.disabled):after {
        margin-bottom: 5px;
    }
}

// #addAddressModal .form-control:disabled {
//     background-color: white;
//     opacity: 0.5;
// }

#addressHistory {
    margin-top: 20px;
}
#addressHistory div.row {
    border-top: 1px #999999 solid;
    padding: 15px 0px;
}

#addressHistory div.row:last-child {
    border-bottom: 1px #999999 solid;
}
.pac-container {
    z-index: 1051 !important;
}

#addressHistory .alert[class*="--compact"] {
    border: 0.15rem solid #002e6b;
    color: black;
    border-radius: 10px;
}

#USPSValidationModal {
    .alert[class*="-danger"] {
        color: #e21224;
        border-radius: 10px;
    }
    .alert[class*="-danger"]::before {
        background-image: url(../img/error-warning.svg);
    }
    #ConfirmBtn {
        text-transform: capitalize;
    }

    #BackBtn {
        margin-right: 20px;
    }
    .modal-footer div {
        float: right;
        margin-bottom: 20px;
    }
}
