.custom-card-container {
    background: #fff;
    border-top: 0.1rem solid #c1c9d5;
    border-bottom: 0.1rem solid #c1c9d5;
    border: 0 solid #c1c9d5;
    border-radius: 0.8rem;
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    box-shadow:
        0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.05),
        0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.13);
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;

    &.sub-card {
        padding: 24px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin: 24px;

        &:hover {
            cursor: pointer;

            strong {
                text-decoration: underline;
            }
        }

        .left {
            font-size: 16px;
            font-family: Lato;
            font-weight: 600;
            line-height: 24px;
            color: #004db2;
            word-wrap: break-word;
        }

        .right {
            .complete {
                display: flex;
                width: 100%;
                height: 22px;
                font-family: BrandonText;
                color: #10542c;
                text-decoration: unset;
                user-select: none;
                font-size: 16px;
                font-weight: 390;
                line-height: 24px;
                word-wrap: break-word;
                align-items: center;
                justify-content: flex-end;

                .icon {
                    color: inherit;
                }

                span {
                    display: inherit;
                    align-items: center;
                    margin: 0 5px;
                }
            }

            .indicator {
                svg {
                    transform: rotate(0);
                }

                svg {
                    transition: transform 250ms ease-in-out;
                    transform: rotate(180deg);
                }

                &.show {
                    svg {
                        transform: rotate(0);
                        transition: transform 250ms ease-in-out;
                    }
                }
            }
        }
    }

    .body {
        height: 0;
        background: transparent;
        padding: 24px;
        background-color: #f3f4f6;
        border-bottom-right-radius: 0.8rem;
        border-bottom-left-radius: 0.8rem;

        &.show {
            height: auto;
            transition: all 250ms ease-in-out;
        }
    }
}
