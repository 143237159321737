/* Text Styles */
.bo--text-color {
    color: rgba(0, 0, 0, 0.85);
}
.bo--text-color-black {
    color: #000000;
}
.bo-xml-data {
    color: #555555;
}
.bo-dropdown-menu {
    width: auto;
}
.bo--btn-dropdown-link .btn.btn-primary.dropdown-toggle {
    //.bo--btn-dropdown-link .btn.btn-primary.dropdown-toggle:hover,
    //.bo--btn-dropdown-link .btn.btn-primary.dropdown-toggle:focus {
    display: block;
    font-size: 1.6rem;
    font-weight: 600;
    width: auto;
    font-family: MMSharpSans, Helvetica, Arial, sans-serif;
    padding: 0 1.6rem !important;
    border-color: transparent;
    background-color: transparent;
    text-align: right;
    border: 0px !important;
}
.bo--btn-dropdown-link .btn.btn-primary.dropdown-toggle::after {
    border-width: 0;
    content: "" !important;
    margin: -0.8rem 0 0 0.8rem;
    color: #004db2;
    background-image: none !important;
    transform: none !important;
    -webkit-transform: none !important;
    margin-left: 0.8rem !important;
}
.PhoneInputInput {
    display: block;
    width: 100%;
    height: 4rem;
    padding: 0.8rem 1.6rem;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.33;
    color: #555;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #6b748e;
    border-radius: 0.4rem;
    transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}
.nav-link {
    text-transform: none !important;
}
