.ccb-custom-timeline { 
    margin-bottom: 50px;
    display: flex;
    width: 100%;

    .ccb-timeline {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .ccb-timeline-item {
            display: flex;

            &:not(.last-item) {
                width: 100%;
            }

            .ccb-timeline-section-wrapper {
                display: flex;
                width: 100%;
                align-items: center;

                .ccb-timeline-content {
                    position: relative;
                    
                    .ccb-timeline-item-icon {
                        &.active {
                            path {
                                fill: #004DB2;
                            }
                        }

                        &.not-applicable {
                            path {
                                fill: #6B748E;
                            }
                        }
                    }
            
                    .ccb-timeline-item-content {
                        position: absolute;
                        top: 30px;
                        width: 150px;

                        .ccb-timeline-item-title, .ccb-timeline-item-description {
                            color: #555555;
                            font-size: 12px;
                            font-family: Lato;
                            font-weight: 400;
                            line-height: 16px;
                            word-wrap: break-word;
        
                            &.not-applicable {
                                text-decoration: line-through;
                            }

                            &.active {
                                color: #000;
                            }
                        }
                    }
                }

                .line-separator {
                    display: flex;
                    margin-bottom: 0;
                    margin: 0 5px;
                    height: .4rem;
                    width: 100%;
                    border-radius: .4rem;
                    background: #C1C9D5;
                    
                    &.completed {
                        background: #004db2;
                    }
                }
            }
        }
    }
}
