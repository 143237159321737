.psr-cell {
  vertical-align: middle;
  border-top: 1px solid #c1c9d5;
  word-break: break-word;
}
.psr-cell-center {
  vertical-align: middle !important;
  border-top: 1px solid #c1c9d5 !important;
  text-align: center !important;
}
.psr-cell-no-border {
  vertical-align: middle !important;
}
.psr-cell-center-no-border {
  vertical-align: middle !important;
  text-align: center !important;
}
.psr-cell-left-no-border {
  vertical-align: middle !important;
  text-align: left !important;
}
.psr-row {
  height: 55px;
}
.psr-row-gray {
  height: 55px;
  background-color: #e9ecef;
}
.psr-chevron {
  margin-right: 20px !important;
}
.psr-chevron-padding {
  padding-left: 100px;
}
.psr-accordion {
  margin-left: 25px;
  padding-bottom: 15px;
  padding-top: 15px;
}
.psr-add-button {
  text-transform: none !important;
}
.mm-collapse {
  padding: 0 !important;
}
.psr-first-row {
  padding-top: 15px;
  padding-bottom: 15px;
  display: block;
}

.psr-modal-job-dates,
.psr-modal-job-title {
  margin-top: 10px;
}
.psr-modal-job-dates {
  margin-bottom: 10px;
}
.psr-modal-job-title {
  word-break: break-word;
}

#psrTable {
  .psr-table-row {
    display: flex;
    .psr-table-jobtitle-column,
    .psr-table-company-column {
      width: 30%;
      word-break: break-word;
      display: flex;
    }
    .psr-table-jobtitle-column,
    .psr-table-company-column,
    .psr-table-date-column {
      margin: 10px 10px 10px 0px;
    }
    .psr-table-date-column {
      width: 20%;
    }
    .psr-add-btn-wrapper {
      float: right;
      width: 87px;
      margin: 5px 0px 5px 0px;
      .psr-add-button {
        padding: 7px 10%;
      }
    }
    .psr-add-btn-wrapper:nth-child(1) {
      margin: 10px 0px 10px 0px;
    }
    .psr-table-button-column {
      float: right;
      width: 87px;
      margin: 10px 0px 10px 0px;

      span {
        color: #3174af;
        cursor: pointer;
        font-size: 1.6rem;
        padding: 7px 10%;
        min-width: 14px;
        strong {
          padding: 7px 20%;
          white-space: nowrap;
        }
      }
    }
  }
  .psr-company-separator {
    border-top: 1px solid #c1c9d5 !important;
    padding-top: 10px;
  }
}
