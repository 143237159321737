.drp-sum-list {
    hr {
        width: auto;
        border: 0.8px #c1c9d5 solid;
        margin-top: 0;
        margin-bottom: 0;
    }

    .list-header {
        display: flex;
        width: 100%;
        flex-direction: row;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-family: Lato;
        font-weight: 700;
        line-height: 20px;
        margin-bottom: -13px;
        word-break: break-all;
        margin-bottom: 10px;
        height: auto;
        min-height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .left {
            display: inherit;
            width: 100%;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            font-family: Lato;
            font-weight: 700;
            line-height: 20px;
            word-break: break-all;
            align-items: center;

            &.two-col > div:first-child {
                flex: 1 0 80px;
                min-width: 135px;
                max-width: 250px;
            }
            &.ind-crim-row {
                > div:first-child {
                    flex: 1;
                    padding-right: 10px;
                }
                > div:last-child {
                    flex: 2;
                }
            }
            &.org-crim-row {
                > div {
                    flex: 1;
                    &:first-child {
                        flex: 1;
                        padding-right: 10px;
                    }
                    &:nth-child(2) {
                        flex: 2;
                        padding-right: 10px;
                    }
                    &:last-child {
                        flex: 2;
                    }
                }
            }
            &.regulatory-row {
                > div {
                    &:first-child {
                        flex: 1;
                        padding-right: 10px;
                    }
                    &:nth-child(2) {
                        flex: 2;
                        padding-right: 10px;
                    }
                    &:last-child {
                        flex: 2;
                    }
                }
            }
            &.investigation-row {
                > div {
                    &:first-child {
                        flex: 1;
                        padding-right: 10px;
                    }
                    &:nth-child(2) {
                        flex: 2;
                        padding-right: 10px;
                    }
                    &:last-child {
                        flex: 2;
                    }
                }
            }
            &.civil-judicial-row {
                > div {
                    flex: 1;
                    &:first-child {
                        flex: 1;
                    }
                    &:nth-child(2) {
                        flex: 2;
                        padding-right: 10px;
                    }
                    &:last-child {
                        flex: 2;
                    }
                }
            }
            &.customer-complaint-row {
                > div {
                    &:first-child {
                        flex: 1 0 80px;
                        min-width: 100px !important;
                        max-width: 150px !important;

                        @media (max-width: 1000px) {
                            max-width: 125px !important;
                            min-width: 100px !important;
                        }
                    }
                    &:nth-child(2) {
                        flex: 1 0 105px;
                        min-width: 125px !important;
                        max-width: 300px !important;
                    }
                    &:last-child {
                        flex: 1 0 80px;
                    }
                }
            }
            &.personal-bankruptcy-row {
                > div {
                    width: 31%;
                    min-width: 100px;
                    max-width: 200px;
                }
            }
            &.organizational-bankruptcy-row {
                > div {
                    flex: 1;
                    width: 0;
                    &:first-child {
                        max-width: 115px !important;
                    }
                    &:nth-child(2) {
                        max-width: 225px !important;
                    }
                }
            }
            &.judgment-lien-row {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                > div {
                    flex: 1 0 50px;
                    width: 0;
                    &:first-child {
                        min-width: 150px !important;
                        max-width: 200px !important;
                    }
                    &:nth-child(2) {
                        min-width: 125px !important;
                        max-width: 200px !important;
                    }
                    &:last-child {
                        padding-right: 20px;
                    }
                }
            }
        }

        .action {
            cursor: pointer;
            display: flex;
            flex: 1;
            min-width: 60px !important;
        }
    }

    .rows {
        .rows-wrapper {
            width: 100%;

            .drp-list-row {
                display: flex;
                width: 100%;
                color: rgba(0, 0, 0, 0.85);
                font-size: 14px;
                font-family: Lato;
                font-weight: 400;
                line-height: 16px;
                word-break: break-all;
                height: auto;
                min-height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;

                &.is-draft {
                    width: 100%;
                    background: transparent;
                    background-color: #f3f4f6;
                }

                .action {
                    cursor: pointer;
                    position: relative;
                    left: -5px;
                    flex: 1;
                }

                .left {
                    display: inherit;
                    width: 100%;
                    color: rgba(0, 0, 0, 0.85);
                    font-size: 14px;
                    font-family: Lato;
                    font-weight: 400;
                    line-height: 16px;
                    word-break: break-all;
                    align-items: center;

                    &.two-col > div:first-child {
                        flex: 1 0 80px;
                        min-width: 135px;
                        max-width: 250px;
                    }
                    &.ind-crim-row {
                        > div:first-child {
                            flex: 1;
                            padding-right: 10px;
                        }
                        > div:last-child {
                            flex: 2;
                            padding-right: 10px;
                        }
                    }
                    &.org-crim-row {
                        > div {
                            flex: 1;
                            width: 0;
                            &:first-child {
                                flex: 1;
                                padding-right: 10px;
                            }
                            &:nth-child(2) {
                                flex: 2;
                                padding-right: 10px;
                            }
                            &:last-child {
                                flex: 2;
                                padding-right: 10px;
                            }
                        }
                    }
                    &.regulatory-row {
                        > div {
                            &:first-child {
                                flex: 1;
                                padding-right: 10px;
                            }
                            &:nth-child(2) {
                                flex: 2;
                                padding-right: 10px;
                            }
                            &:last-child {
                                flex: 2;
                                padding-right: 10px;
                            }
                        }
                    }
                    &.investigation-row {
                        > div {
                            &:first-child {
                                flex: 1;
                                padding-right: 10px;
                            }
                            &:nth-child(2) {
                                flex: 2;
                                padding-right: 10px;
                            }
                            &:last-child {
                                flex: 2;
                                padding-right: 10px;
                            }
                        }
                    }
                    &.civil-judicial-row {
                        > div {
                            flex: 1;
                            &:first-child {
                                flex: 1;
                            }
                            &:nth-child(2) {
                                flex: 2;
                                padding-right: 10px;
                            }
                            &:last-child {
                                flex: 2;
                                padding-right: 10px;
                            }
                        }
                    }
                    &.customer-complaint-row {
                        align-items: normal;
                        margin: 8px 0px;
                        > div {
                            &:first-child {
                                flex: 1 0 80px;
                                min-width: 100px !important;
                                max-width: 150px !important;

                                @media (max-width: 1000px) {
                                    max-width: 125px !important;
                                    min-width: 100px !important;
                                }
                            }
                            &:nth-child(2) {
                                flex: 1 0 80px;
                                min-width: 125px !important;
                                max-width: 300px !important;
                            }
                            &:last-child {
                                flex: 1 0 80px;
                                padding-right: 10px;
                            }
                        }
                    }
                    &.personal-bankruptcy-row {
                        > div {
                            width: 30%;
                            min-width: 100px;
                            max-width: 200px;
                        }
                    }
                    &.organizational-bankruptcy-row {
                        > div {
                            flex: 1 0 50px;
                            width: 0;
                            &:first-child {
                                max-width: 115px !important;
                            }
                            &:nth-child(2) {
                                max-width: 225px !important;
                                padding-right: 10px;
                            }
                        }
                    }
                    &.judgment-lien-row {
                        align-items: normal;
                        text-align: justify;
                        margin: 8px 0px;
                        > div {
                            flex: 1 0 50px;
                            width: 0;
                            &:first-child {
                                min-width: 150px !important;
                                max-width: 200px !important;
                            }
                            &:nth-child(2) {
                                min-width: 125px !important;
                                max-width: 200px !important;
                            }
                            &:last-child {
                                padding-right: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}
