.criminal-disclosure-event-status-form-wrapper {
    .event-status,
    .event-status-date-exp {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
        letter-spacing: 0.01px;
    }

    .event-status {
        margin-top: 24px;
    }

    input::placeholder {
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.01px;
        text-align: left;
    }

    .event-status-date-wrapper {
        margin-top: 24px;

        strong {
            font-family: Lato;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.01px;
            text-align: left;
        }

        .event-status-date-input {
            width: 275px;
        }
    }

    .event-status-date-explanation-container {
        width: 100%;
        padding-right: 30px;
        margin-left: 28px;
    }

    .custom-radio {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.01px;
        text-align: left;
        margin-left: 25px;
    }

    .event-status-date-radio-group {
        margin-top: 10px;
    }

    .backbutton-drp-crim {
        padding-top: 40px;
        padding-bottom: 20px;

        .buttons {
            span {
                margin-right: 25px;
                color: #3174af;
                cursor: pointer;
            }
        }
    }
}
