.civ-jud-details-form-wrapper {
    .crim-discl-drp-summary {
        padding-top: 20px;

        > .drp-main-card {
            width: 100%;
        }
    }

    .is-invalid {
        > #regActionInitiated {
            border: 0.1rem solid #e21224;
        }
    }

    .checkbox-container {
        border: 1px solid #ddd;
        width: 600px;
        height: 300px;
        overflow-y: scroll;
        border-radius: 5px;
    }

    .court-name,
    .court-location,
    .docker-case,
    .court-action {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
    }

    .court-action-other {
        width: 360px;
        margin-left: 50px;
    }

    .org-desctiption-label {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 700;
        width: 100%;
        line-height: 24px;
        letter-spacing: 0.5px;
        word-wrap: break-word;
        margin-bottom: 24px;
        padding-left: 1.6rem;
    }

    .full-name-of-reg-container {
        margin-top: 24px;
        width: calc(80% - 25px);
        margin-left: 25px;
    }

    .court-action-other {
        margin-top: 24px;
        width: 360px;
        margin-left: 50px;
    }

    .empl-firm,
    .proc-name {
        margin-bottom: 24px;
    }

    .org-name,
    .inv-business,
    .pos-title-rel,
    .full-name-of-reg,
    .disp-of-charge-label {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;

        strong {
            font-family: Lato;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.01px;
            text-align: left;
        }
    }

    .empl-firm {
        margin-top: 24px;
    }

    .comments-label {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
    }

    .crim-comments-container {
        margin-top: 24px;
        width: 80%;
    }

    .event-status,
    .event-status-date-exp {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
    }

    .event-status {
        margin-top: 24px;
    }

    .event-status-date-wrapper {
        margin-left: -15px;
        margin-top: 24px;

        strong {
            font-family: Lato;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.01px;
            text-align: left;
        }

        .event-status-date-input {
            width: 275px;
        }
    }

    .event-status-date-explanation-container {
        width: 100%;
        padding-right: 30px;
        margin-left: 20px;
    }

    .custom-radio {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.01px;
        text-align: left;
        margin-left: 18px;
    }

    .event-status-date-radio-group {
        margin-top: 10px;
    }

    .date-of-disp {
        margin-top: -5px;
    }

    .event-status-date-radio-group {
        margin-top: 10px;
    }

    .disp-of-charge-container {
        margin-top: 15px;
    }

    .nr-of-counts-group {
        width: 275px;
    }

    .disp-of-charge {
        button {
            font-family: Lato;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.01px;
            text-align: left;
            color: #6b748e;
        }
    }

    .event-status-date-explanation-container {
        width: 80%;

        textarea {
            min-height: 80px;
        }
    }

    .backbutton-drp-crim {
        padding-top: 40px;
        padding-bottom: 20px;

        .buttons {
            span {
                margin-right: 25px;
                color: #3174af;
                cursor: pointer;
            }
        }
    }
}
