.cust-complaint-disclosure-civil-litigation-form-wrapper {
    .customer-complaint-label {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
        margin-top: 10px;
        letter-spacing: 0.01px;
    }
    .court-action-other {
    }

    .date-wrapper {
        margin-bottom: 10px;
    }
    .form-control,
    .dropdown-toggle,
    .input-group-text {
        border-width: 1.5px !important;
    }
    .form-control .is-invalid {
        border-color: #e21224 !important;
        color: #e21224 !important;
    }
    .is-invalid #civLitDispositionOption,
    .input-group.is-invalid > #civLitMonetaryComp,
    .input-group.is-invalid > #civLitContribAmount,
    .input-group.is-invalid .input-group-text,
    .input-group.is-invalid input:focus {
        border-color: #e21224 !important;
        color: #e21224 !important;
        .show {
            display: block !important;
        }
    }
    .input-group {
        margin-bottom: 0px !important;
    }

    .is-invalid #civLitDispositionOption {
        border-color: #e21224 !important;
        color: rgba(0, 0, 0, 0.4) !important;
    }
    .status-placeholder button {
        color: rgba(0, 0, 0, 0.4) !important;
    }
    .custom-radio {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.01px;
        text-align: left;
        margin-left: 25px;
    }

    .drp-radio-input-wrapper div:last-child {
        margin-bottom: 0px;
    }

    .backbutton-drp-civ-lit {
        padding-top: 40px;
        padding-bottom: 20px;

        .buttons {
            span {
                margin-right: 25px;
                color: #3174af;
                cursor: pointer;
            }
        }
    }
}
