.drp-generic-checkbox-list-container {
    .checkbox-label {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;

        strong {
            font-family: Lato;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.01px;
            text-align: left;
        }
    }

    .value-container {
        display: flex;
        margin-bottom: 12px;
    }

    .other-input-wrapper {
        position: relative;
        left: -60px;
        top: 40px;
    }

    .checkbox-list-card {
        &.is-scroll {
            box-shadow:
                0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.05),
                0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.13);
            scrollbar-color: #6b748e transparent;
            scrollbar-width: thin;
            scroll-behavior: smooth;
            border: 1.5px solid #6b748e;
            overflow-y: scroll;
            padding: 30px;
            border-radius: 5px;
        }

        padding: 12px 0 0 16px;
        width: 520px;
        height: 328px;
        margin-bottom: 24px;

        .label-strong {
            cursor: pointer;
            font-size: 16px;
            font-family: Lato;

            &.is-scroll {
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.01px;
                text-align: left;
            }

            color: black;
            font-weight: 500;
            line-height: 24px;
            word-wrap: break-word;
        }
    }
}
