.criminal-disclosure-court-details-form-wrapper {
    .crim-discl-drp-summary {
        padding-top: 20px;

        > .drp-main-card {
            width: 100%;
        }
    }

    .checkbox-container {
        border: 1px solid #ddd;
        width: 600px;
        height: 300px;
        overflow-y: scroll;
        border-radius: 5px;
    }

    .court-name,
    .court-location,
    .docker-case,
    .court-action {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
        letter-spacing: 0.01px;
    }

    input::placeholder {
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.01px;
        text-align: left;
    }

    .court-location,
    .docker-case,
    .court-action {
        margin-top: 24px;
    }

    .court-action-other {
        width: 360px;
        margin-left: 50px;
    }

    .custom-radio {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.01px;
        text-align: left;
        margin-left: 25px;
    }

    .backbutton-drp-crim {
        padding-top: 40px;
        padding-bottom: 20px;

        .buttons {
            span {
                margin-right: 25px;
                color: #3174af;
                cursor: pointer;
            }
        }
    }
}
.safari-only-wrapper {
    @media (min-width: 1400px) and (max-width: 1600px) {
        .custom-control .custom-control-label::before,
        .custom-control .custom-control-label::after {
            top: 1rem !important;
        }
    }
    @media (min-width: 1600px) and (max-width: 1800px) {
        .custom-control .custom-control-label::before,
        .custom-control .custom-control-label::after {
            top: 0.9rem !important;
        }
    }
    @media (min-width: 1800px) {
        .custom-control .custom-control-label::before,
        .custom-control .custom-control-label::after {
            top: 0.7rem !important;
        }
    }
}
