#reviewEmploymentHistory {
  hr {
    color: #c1c9d5;
  }
  .row:not(:first-child) {
    padding-top: 15px;
  }
  .employment-history-grid {
    margin-top: 25px;
    .timeline-li {
      height: auto;
    }
  }
  .toggle-page-actions {
    margin-top: 50px;
    display: inline-flex;
    float: right;
    .back-btn-wrapper {
      margin-right: 20px;
      margin-top: 5px;
      color: rgb(49, 116, 175);
      cursor: pointer;
    }
  }
  .add-employment-btn {
    color: rgb(49, 116, 175);
    cursor: pointer;
    strong {
      padding-left: 5px;
    }
  }
  .mm-vertical-timeline ul {
    margin-bottom: 0;
  }
  .mm-vertical-timeline {
    margin-top: 50px;
    margin-left: 4px;
    li::before {
      position: relative;
      top: 40px;
      content: "";
      display: flex;
      height: 10px;
      width: 10px;
      border-radius: 50%;
    }
    li.active::before {
      background-color: #c1c9d5 !important;
    }
    li.complete::before {
      background-color: #002f6c !important;
    }

    .custom-table-row {
      display: inline-flex;
      width: 100%;
      .custom-table-column {
        font-size: 14px;
      }
      .custom-table-column:first-child {
        margin-left: 10px;
        width: 105px;
      }
      .custom-table-column:last-child {
        border-top: 1px solid rgb(193, 201, 213);
        width: 100%;
        margin-left: 10px;
        padding: 22px 10px 20px 10px;
      }
      .employment-history-address {
        float: left;
        width: 90%;
      }
    }

    .custom-table-row-no-border {
      display: inline-flex;
      width: 100%;
      .custom-table-column {
        font-size: 14px;
      }
      .custom-table-column:first-child {
        margin-left: 10px;
        width: 105px;
      }
      .custom-table-column:last-child {
        width: 100%;
        margin-left: 10px;
        padding: 22px 10px 20px 10px;
      }
      .employment-history-address {
        float: left;
        width: 90%;
      }
    }

    .last-address {
      border-bottom: 1px solid rgb(193, 201, 213);
    }
  }

  .timeline-date-text {
    color: #555;
  }
  .timeline-li {
    margin-left: -2.3rem !important;
    margin-bottom: 0px !important;
    //height: 68px !important;
    position: relative;
    top: -10px;
  }
  @-moz-document url-prefix() {
    .mm-vertical-timeline > li.active::before {
      top: 9%;
    }
    .mm-vertical-timeline > li.complete::before {
      top: 9%;
    }
  }

  .bo--text-color {
    word-wrap: break-word;
    word-break: break-word;
  }
}
