#professionalDesignationsForm {
    .invalid-feedback {
        display: block;
    }

    .custom-control-input.is-invalid ~ .custom-control-label {
        color: #555;
    }
    .custom-control-label strong {
        color: black;
    }

    #pdQuestion {
        padding-top: 20px;
    }
    .pd-certifications,
    .designations {
        padding-top: 10px;
    }

    .date-wrapper {
        padding-left: 45px;
        label {
            padding-top: 5px;
        }
    }
    .actions {
        float: right;
        .col-md-12 {
            display: inline-flex;
        }
        #pdBackButton {
            margin-right: 20px;
            font-size: 16px;
            padding-top: 3px;
        }
        .mm-dots-loading-lg {
            margin: 0 calc(3.6rem * 0.4);
        }
    }
}
