//DRP Header
.drp-layout-wrapper {
    .drp-layout-return {
        text-align: left;
        margin: 25px 50px 25px 25px;
        color: #3174af;
        cursor: pointer;

        strong {
            padding-left: 5px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .section-details-container {
        display: flex;
        justify-content: space-between;

        .guide {
            font-family: BrandonText;
            font-size: 14px;
            font-weight: 390;
            line-height: 20px;
            letter-spacing: 0px;
            text-align: right;

            &.with-padding {
                position: relative;
                bottom: 40px;
            }
        }
    }

    .drp-layout-section {
        margin-top: -10px;
        margin-bottom: 24px;
        color: black;
        font-size: 18px;
        font-family: MMSharpSans;
        font-weight: 600;
        line-height: 26px;
        word-wrap: break-word;
    }

    .drp-layout-details {
        margin-bottom: 14px;
    }

    .drp-layout-description {
        margin-bottom: 24px;
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > .mm-dots-loading {
            position: absolute;
            right: -15px;
        }
    }

    .mm-dots-loading {
        &.save {
            position: absolute;
        }
    }

    .isRequired {
        margin-top: 0.25rem;
        font-size: 1.4rem;
        color: #e21224;
    }
}

#ReviewCriminalDRP,
#ReviewRegulatoryActionDRP,
#ReviewInvestigationDRP,
#ReviewCivilJudicialDRP,
#ReviewCustComplaintDRP,
#ReviewTerminationDRP,
#ReviewBankruptcyDRP,
#ReviewBondDRP,
#ReviewJudgmentLienDRP {
    overflow-wrap: break-word;
}
