#startapplication {
  .is-invalid:focus {
    border-color: #e21224;
  }
}
.myapp {
  .content {
    background: white;
    padding: 15px 20px 20px 20px;
    width: 100%;
    display: none;
  }
  .active-content {
    display: block;
    color: black;
  }
}

.indicator {
  margin-right: -5px !important;
}

a {
  color: #002f6c !important;
}
p a {
  color: black !important;
}
.footer-link {
  color: #004db2 !important;
}

button:focus {
  outline: none;
}
.label {
  width: 100%;
  font-weight: bold;
  padding-top: 15px;
}
.required-field-text {
  font-size: 14px;
  color: #555555;
}
.form-subheading-text {
  font-family: MMSharpSans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}
select.minimal {
  background-image: linear-gradient(45deg, transparent 50%, black 50%),
    linear-gradient(135deg, black 50%, transparent 50%); //,
  //linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}
.current-indicator {
  //font-family: Brandon Text;
  //font-style: normal;
  font-weight: 420;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #327320;
  padding-left: 0px;
}
.show-current-indicator {
  display: block;
}
.hide-current-indicator {
  display: none;
}
.select-options {
  color: #002f6c;
  text-align: left;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.authQuestions {
  //font-family: 'Brandon Text';
  //font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: black;
  padding-top: 15px;
}

.custom-control {
  z-index: 0;
}

#startAppHeader.row .col-8 {
  padding: 2%;
  text-align: center;
}

#startAppHeader.row .col-2 span {
  cursor: pointer;
  display: inline;
}

#startAppHeader.row .col-2 span h3 span.logout-button-text {
  margin-bottom: 7px;
  margin-left: 7px;
  color: hsl(214, 100%, 21%);
}
#logoutModal {
  .close:only-child {
    top: 10px;
  }
  .logout-modal-text {
    font-size: 1.4rem;
  }
  .logout-modal-button-wrapper {
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-flex;
    .btn-primary {
      font-size: 12px;
      margin-right: 10px;
    }
    .btn-link {
      padding: 4px 0px;
    }
  }
}
#logoutModal.modal-dialog,
#releaseOfInfoModal.modal-dialog {
  top: 10%;
}

@media (min-width: 2200px) {
  .safari-only-wrapper #logoutModal {
    max-width: 700px;
    .btn-link {
      padding: 10.8px 0px;
    }
  }
}

@media (min-width: 2500px) {
  .safari-browser-only {
    .custom-control.custom-radio {
      label {
        margin-top: 5px;
      }
      label:before,
      label:after {
        top: 0.7rem;
      }
    }
  }
}

#reviewSignInjectedText {
  span {
    float: left !important;
    margin-bottom: 15px;
  }
}
.modal-overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(80, 80, 80, 0.6);
  z-index: 1000;
}

.modal-content {
  padding: 1rem !important;
}

h3 {
  margin-bottom: 0 !important;
}

.validation-error::placeholder {
  color: #e21224;
}

.dropdown-toggle {
  text-transform: none !important;
}

.help-text {
  font-size: 12px;
  color: #555555;
}
.dropdownmenu {
  position: absolute;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
  right: 60px;
}

.dropdown-content span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-weight: 500;
  cursor: pointer;
}

.dropdownmenu span:hover {
  background-color: #f3f4f6;
}

.show {
  display: block !important;
}

.card .accordion .mm-collapse {
  border: none;
}

.card .accordion .mm-collapse .btn-collapse:focus {
  outline: none;
}

.btn-collapse:hover {
  text-decoration: none !important;
}

.input-group-text-pad-right {
  padding-right: 0px !important;
}

.input-pad-left {
  padding-left: 5px !important;
}
