.cust-compl-disclosure-status-details-form-wrapper {
    .cust-compl-discl-drp-summary {
        padding-top: 20px;

        > .drp-main-card {
            width: 100%;
        }
    }

    .drp-main-card {
        scrollbar-color: #6b748e transparent;
        scrollbar-width: thin;
        scroll-behavior: smooth;
        border-width: 1.5px;
        overflow-y: auto;
        border-radius: 5px;
        //overflow: -moz-scrollbars-vertical;
    }
    textarea,
    input[type="text"],
    .input-group-text {
        border-width: 1.5px;
        border-radius: 5px;
    }

    .input-group {
        margin-bottom: 0;
    }
    .drp-main-card {
        max-height: 490px;
        border-color: #6b748e;
    }

    #customerComplaintState {
        border-width: 1.5px;
    }

    .checkbox-container {
        border: 1px solid #ddd;
        width: 600px;
        height: 300px;
        overflow-y: scroll;
        border-radius: 5px;
    }
    .status-details-instructions,
    .cust-complaint-action,
    .customer-names,
    .customer-state,
    .customer-other-states,
    .customer-employing-firm,
    .customer-allegations,
    .customer-damage-amount,
    .customer-explanation {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
    }

    .customer-state,
    .customer-other-states,
    .customer-employing-firm,
    .customer-allegations,
    .checkbox-label,
    .customer-damage-amount {
        margin-top: 24px;
    }
    .customer-damage-amount-tooltip-wrapper {
        margin-top: 63px;
    }

    .court-action-other {
        width: 360px;
        margin-left: 50px;
    }

    .custom-radio {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.01px;
        text-align: left;
        margin-left: 25px;
    }

    .state-placeholder button {
        color: rgba(0, 0, 0, 0.4) !important;
    }

    .input-group.is-invalid > #statusDetailsCustomerDamageAmount,
    .input-group.is-invalid .input-group-text,
    .input-group.is-invalid input:focus {
        border-color: #e21224 !important;
        color: #e21224 !important;
    }

    .is-invalid #customerComplaintState {
        border-color: #e21224 !important;
        color: rgba(0, 0, 0, 0.4) !important;
    }

    #customerStateError.show {
        display: block !important;
    }

    .backbutton-drp-cust-compl {
        padding-top: 40px;
        padding-bottom: 20px;

        .buttons {
            span {
                margin-right: 25px;
                color: #3174af;
                cursor: pointer;
            }
        }
    }
}
