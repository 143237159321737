.add-contribution-btn {
  color: rgb(49, 116, 175);
  cursor: pointer;
  margin-left: 15px;
  strong {
    padding-left: 5px;
  }
}

#mmlisContribTable {
  .menu-item-hover:hover {
    background-color: #f3f4f6;
  }
  .timeline-date-text {
    color: #555;
  }
  .toggle-page-actions {
    margin-top: 50px;
    display: inline-flex;
    float: right;
    .back-btn-wrapper {
      margin-right: 20px;
      margin-top: 5px;
      color: rgb(49, 116, 175);
      cursor: pointer;
    }
  }
  .add-experience-btn {
    color: rgb(49, 116, 175);
    cursor: pointer;
    strong {
      padding-left: 5px;
    }
  }
  .custom-table-row {
    display: inline-flex;
    width: 100%;
    align-items: center;
    font-size: 14px;

    .mmlis-quick-action-wrapper {
      float: right;
      .icon-quick-actions {
        border: 0;
      }
      #mnuLinkReview,
      #mnuLinkDelete {
        text-align: left;
      }
    }
  }
}
