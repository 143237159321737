#confidential-application {
  text-align: start;
  hr {
    margin: 30px 0px;
  }
  #confidential-app-header {
    .row {
      background-color: #f3f4f6;
      padding-top: 20px;

      .col-8 {
        h2 {
          align-content: center;
          margin-left: 5%;
        }
      }

      .col-4 {
        div {
          display: inline-flex;
          float: right;
          margin-right: 15%;
          div {
            margin-right: 20%;
            button {
              min-width: 0px;
              padding: 0.5rem 2rem;
            }
          }
          span {
            color: rgb(0, 77, 178);
            cursor: pointer;
            float: right;
            margin-top: 5px;
          }
        }
      }
    }
  }
  .container {
    > div {
      margin-top: 30px;
      > .row {
        > .col-12.bo--text-color {
          padding: 20px 10%;
        }
      }
    }
    h1 {
      margin-bottom: 20px;
    }

    #confidential-contract {
      margin-top: 25px;
      .row {
        margin-bottom: 10px;
      }
    }

    #confidential-personal-info {
      .col-1,
      .col-2,
      .col-3,
      .col-4,
      .col-5,
      .col-6,
      .col-7,
      .col-8,
      .col-9,
      .col-10,
      .col-11,
      .col-12 {
        margin-top: 10px;
      }

      .col-8:first-child {
        margin-top: 0;
      }
    }

    .data {
      color: rgb(85, 85, 85);
      overflow-wrap: break-word;
    }

    #confidential-residential-address {
      .row:last-child {
        margin-top: 10px;
      }
    }

    #confidential-business-information,
    #confidential-broker-dealer,
    #confidential-ccb,
    #confidential-eoc,
    #confidential-direct-deposit {
      .row {
        margin-top: 10px;
      }
      .row:first-child {
        margin-top: 0;
      }
    }

    #e-signature-wrapper {
      margin-top: 10px;

      img {
        width: 100%; /* or any custom size */
        height: 100%;
        object-fit: contain;
      }
    }

    #footer-wrapper {
      padding: 0px 20px 20px 0px;
    }

    .corporation-info {
      margin-top: 10px;
    }

    .candidate-info {
      .link-desc-wrapper {
        margin-bottom: 19px;
      }
    
    }

    
  }
}

// .candidate-info {
//   .link-desc-wrapper {
//     margin-bottom: 19px;
//   }

//   .adv-op-hub-mail {
//     margin: 0px 1px 4px 1px;
//     padding: 0;
//     font-size: 1.6rem;
//     text-decoration: underline;
//     font-weight: 400;
//   }
// }

.review-form .candidate-info div.row,
.review-form .big-report div.row {
  margin-top: 15px;
}

.peacock-blue {
  color: #004db2;
}

.word-break {
  word-wrap: break-word;
  word-break: break-word;
}

