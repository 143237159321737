.criminal-disclosure-form-wrapper {
    .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > .mm-dots-loading {
            position: absolute;
            right: -15px;
        }
    }

    .mm-dots-loading {
        &.save {
            position: absolute;
        }
    }

    .isRequired {
        margin-top: 0.25rem;
        font-size: 1.4rem;
        color: #e21224;
    }

    .sub-card-container {
        display: flex;
        flex-direction: column;

        .save-edit-btn-wrapper {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .edit,
            .save {
                cursor: pointer;
                display: flex;
                width: auto;
                align-items: flex-end;
                justify-content: flex-end;
                color: #004db2;
                font-size: 16px;
                font-family: MMSharpSans;
                font-weight: 600;
                text-transform: capitalize;
                line-height: 16px;
                letter-spacing: 0.5px;
                word-wrap: break-word;
                padding-right: 16px;

                &:hover {
                    text-decoration: underline;
                }

                &.disabled {
                    cursor: not-allowed;
                    color: #c1c9d5;
                    text-decoration: unset;
                }
            }

            .edit {
                width: 60px;
                margin-bottom: 8px;
            }
        }

        .category {
            color: black;
            font-size: 16px;
            font-family: Lato;
            font-weight: 700;
            text-transform: capitalize;
            line-height: 24px;
            letter-spacing: 0.5px;
            word-wrap: break-word;

            .header {
                font-family: Lato;
                font-weight: 700;
                margin: 0 0 16px 0;
                text-transform: initial;
            }

            .sub-category {
                font-weight: 400;
                line-height: 24px;
                text-transform: initial;

                .sub-category {
                    margin: 16px 0;
                    margin-left: 60px;
                    font-weight: 400;
                    font-size: 16px;

                    .options {
                        margin: 16px 0;
                        padding-left: 16px;
                        text-transform: capitalize;
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
