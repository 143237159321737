#appconfirmation {
    .container {
        h1 {
            span.mm-illustration-buffer.illustration-confetti {
                min-width: 4rem;
                min-height: 4rem;
            }
        }
        strong span {
            color: black;
        }
        #contract-info {
            div {
                margin-bottom: 15px;
            }
            div:first-of-type {
                margin-top: 30px;
            }
            #contract-forms {
                button {
                    font-size: 16px;
                }
                margin-bottom: 10px;
            }
        }
        p {
            padding-bottom: 15px;
            padding-right: 200px;
        }
        #return-to-dashboard-wrapper {
            padding-bottom: 80px;
            padding-top: 30px;
        }
        #DownloadAllBtn {
            cursor: pointer;
            padding-left: 0;

            span:nth-child(2) {
                font-weight: 450;
                color: #005a9f;
            }
        }
    }
}
