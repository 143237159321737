#charactercreditbonding {
    h3 {
        padding-bottom: 5px;
        padding-top: 5px;

        :first-child {
            padding-top: 10px;
        }
    }
    [id*="reason"] {
        padding-bottom: 10px;
    }

    .authQuestions {
        padding-top: 0px;
    }
    #actions {
        text-align: right;
        padding-top: 30px;

        #ccb_back {
            margin-right: 25px;
            color: rgb(49, 116, 175);
            cursor: pointer;
        }
    }
    .custom-control-label {
        color: #555;
    }

    [id*="yes"],
    [id*="no"] {
        color: #555;
    }
    [id*="_error"].invalid-feedback {
        display: block;
    }
}
