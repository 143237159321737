.home-page-active-apps {
    //maybe add .content and display: flex to shrink the look of overall component size
    border: 0px;
    border-radius: 10px;
    background-color: #f3f4f6;
    padding: 30px 24px;
    box-shadow: 1px 1px 1px 0px lightgray;
    margin-bottom: 30px;

    .col-4,
    .col-8 {
        display: flex;
        align-items: center;
    }
    .col-4 {
        .contract-forms {
            margin-left: auto;
            min-height: 100px;
            min-width: 269px;
            display: flex;
            align-items: center;
            .mm-dots-loading {
                margin-left: auto;
            }
        }
    }

    h2,
    span,
    #finetext {
        color: black;
    }

    #finetext {
        font-family: Lato;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 2px;
        text-align: left;
    }
    .buttons {
        margin: 0 0 0 auto;
        .icon-caret {
            margin-left: 10px;
        }
        .continue-button {
            min-width: 276px;

            color: white;
            text-transform: capitalize;
            font-family: MMSharpSans;
            font-weight: 600px;
            font-size: 16px;
            line-height: 24px;
        }
        .continue-button:hover {
            background-color: #004db2;
            color: white;
            border-color: #004db2;
            text-transform: capitalize;
            font-family: MMSharpSans;
            font-weight: 600px;
            font-size: 16px;
            line-height: 24px;
        }
    }
    .new-bubble {
        background-color: rgb(0, 46, 107);
        text-transform: none;
        font-weight: 500;
        color: white !important;
        margin-right: 10px;
        margin-bottom: 10px;
    }
    .contract-type-and-addons,
    .contract-type,
    .addons {
        display: inline-block;
    }
    .addons {
        padding-left: 20px;
    }

    .contract-type {
        margin-bottom: 0px;
    }

    .application-status-as-of {
        margin-top: 10px;
        padding-bottom: 20px;
    }
    .application-timeline {
        display: inline-flex;
        .strike-through {
            width: 25px;
            margin: 1rem 0px !important;
            border-top: 2px solid #c1c9d5;
        }
    }

    .corporation-name {
        margin-top: 8px;
        color: black;
        word-break: break-word;
    }

    .badge {
        border-radius: 10px;
        &.badge-primary {
            color: black;
            background-color: white;
        }
        &.badge-primary-current {
            color: #10542c;
            background-color: #dce8d9;
        }
        &.badge-primary-error {
            color: white;
            background-color: red;
        }
    }
}
