#add-new-charge-modal {
    padding-top: 240px;
    padding-bottom: 240px;

    .drp-modal-header-wrapper {
        padding: 24px 24px 0 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        max-width: 100%;
        align-items: center;

        h2 {
            font-family: MMSharpSans;
            font-size: 20px;
            font-weight: 700;
            line-height: 28px;
            letter-spacing: 1px;
            text-align: left;
            color: #000;
        }
    }

    .inv-business {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
    }

    .drp-radio-input-wrapper {
        margin-left: 30px;
    }

    .inv-business {
        margin-top: 24px;
    }

    .is-invalid {
        border-color: red;
    }

    .event-status {
        margin: 24px 0 0 0;
        padding: 0;
    }

    .event-status-date-wrapper {
        margin: 24px 0 0 0;
        padding: 0;

        strong {
            font-family: Lato;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.01px;
            text-align: left;
        }

        .event-status-date-input {
            width: 275px;
        }
    }

    .event-status-date-exp {
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.01px;
        text-align: left;
    }

    .event-status-date-explanation-container {
        width: 100%;
        margin-left: 30px;
        padding-right: 32px;

        textarea {
            min-height: 80px;
        }
    }

    .req-field {
        span {
            margin-left: 24px;
            margin-right: 24px;
            font-family: Brandon Text;
            font-size: 14px;
            font-weight: 390;
            line-height: 20px;
            letter-spacing: 0.01px;
            text-align: left;
            color: #000000d9;
        }
    }

    .req-field-sub {
        color: #000000d9;
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.01px;
        text-align: left;
        margin: 15px 24px 10px 24px;
    }

    .drp-radio-input-wrapper {
        margin-left: 15px;
    }

    .children-container {
        margin: 0 24px 48px 24px;

        .event-status-date-exp,
        .formal-charge-label,
        .nr-of-counts,
        .event-status,
        .plea-each-charge,
        .disp-of-charge-label {
            font-family: Lato;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.01px;
            text-align: left;
            color: #000000;
        }

        .formal-charge-container,
        .nr-of-counts-container,
        .event-container {
            margin-bottom: 15px;
        }

        .disp-of-charge-container {
            margin-top: 15px;
        }

        .nr-of-counts-group {
            width: 275px;
        }

        .disp-of-charge {
            button {
                font-family: Lato;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.01px;
                text-align: left;
                color: #6b748e;
            }
        }
    }

    .disp-of-charge.is-invalid > button {
        border: 0.1rem solid red;
    }

    .drp-add-new-charge-btns {
        padding-top: 25px;
        float: right;
    }

    .disp-of-charge-label {
        color: red;
    }

    .event-status-date-explanation-container-am-re {
        margin-top: 15px;
    }

    .nr-of-counts-container, .event-status-date-wrapper {
        input::placeholder {
            font-family: Lato;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.01px;
            text-align: left;
        }

        input {
            padding-left: 15px !important;
        }
    }

    #formalChargeId:focus,
    #nrOfCountsId:focus,
    #pleaEachCharge:focus,
    #deferedExp:focus,
    #otherExp:focus,
    #otherText:focus,
    #pleaEachReducedCharge:focus,
    #otherReducedExp:focus,
    #otherAmendedExp:focus,
    #reducedChargeId:focus,
    #nrOfAmendedCountsId:focus,
    #otherText:focus,
    #pleaEachAmendedCharge:focus,
    #dispOfAmendedChargeId:focus,
    #dispOfChargeId:focus,
    #amendedDescChargeId:focus,
    #nrOfAmendedCountsId:focus {
        &.is-invalid {
            border-color: red;
        }
    }

    .sentence-hr {
        padding-top: 50px;
        padding-bottom: 25px;
        display: flex;
        max-width: 762px;
        font-family: Lato;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 2px;
        text-align: center;
        margin-top: -15px;

        &.expl {
            padding-top: 15px;
        }

        .eyebrow-text-wrapper {
            strong {
                width: max-content;
            }
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 10px 0 10px;
        }

        > hr {
            width: 100%;
            height: 0px;
            border: 0.8px #c1c9d5 solid;
            margin-left: 0;
        }
    }

    .custom-hr {
        margin-top: 50px;
        max-width: 762px;
        height: 0px;
        border: 0.8px #c1c9d5 solid;
        margin-left: 0;
        margin-bottom: 5px;
    }

    .event-status {
        margin-top: 24px;
    }

    .event-status-date-wrapper {
        strong {
            font-family: Lato;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.01px;
            text-align: left;
        }

        .event-status-date-input {
            width: 275px;
        }
    }

    .amended-date,
    .reduced-date {
        margin-bottom: 15px;
    }

    .event-status-date-explanation-container {
        width: 100%;
    }

    .court-name,
    .court-location,
    .docker-case,
    .court-action,
    .req-type-label {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
    }

    .court-location,
    .docker-case,
    .court-action {
        margin-top: 15px;
    }

    .court-action-other {
        width: 360px;
        margin-left: 25px;
    }

    .custom-radio {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.01px;
        text-align: left;
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .addChargeButton {
            text-transform: capitalize;
        }

        > .mm-dots-loading {
            position: absolute;
            right: -15px;
        }
        .back-button {
            margin-right: 25px;
            color: #3174af;
            cursor: pointer;
        }
    }
}
