.drp-main-card {
    margin-bottom: 40px;
    padding: 24px;
    background: white;
    border-radius: 8px;
    border: 1px #d9d9d6 solid;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    display: inline-flex;
    
    &.is-drp {
        max-height: 425px;
        scrollbar-color: #6b748e transparent;
        scrollbar-width: thin;
        scroll-behavior: smooth;
        overflow-y: auto;
    }

    .header-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        word-wrap: break-word;
        letter-spacing: 0.5px;

        .header {
            font-family: Lato;
            color: black;
            font-weight: 700;
            line-height: 24px;
        }

        .add-drp-btn-wrapper {
            .add-drp-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 137px;
                border-color: #004db2;

                .btn-content {
                    display: flex;

                    strong,
                    span {
                        font-family: MMSharpSans;
                        color: #004db2;
                        font-weight: 600;
                        text-transform: none;
                    }

                    strong {
                        width: max-content;
                    }

                    span {
                        display: flex;
                        align-items: center;
                        margin-right: 5px !important;
                    }
                }

                &:hover {
                    .btn-content {
                        span {
                            svg {
                                g {
                                    fill: white;
                                }
                            }
                        }

                        strong {
                            color: white;
                        }
                    }
                }
            }
        }
    }

    .drp-container {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}
