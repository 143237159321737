.drp-summary-form-wrapper {
    .error {
        position: absolute;
        top: 40px;
        margin-top: 0.25rem;
        font-size: 1.4rem;
        color: #e21224;
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    
        >.mm-dots-loading {
            position: absolute;
            right: -15px;
        }
    }
}
