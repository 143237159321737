.bond-disposition-details-form-wrapper {
    .field-label,
    .info-label,
    .custom-control-label {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
    }
    .custom-control-label {
        font-weight: 500 !important;
    }
    .drp-radio-input-wrapper {
        margin-top: 10px;
        margin-left: 20px;
    }
    textarea {
        width: 70%;
    }
    .col-md-4 {
        min-width: 200px;
    }
    .is-invalid #civLitDispositionOption,
    .input-group.is-invalid > #payoutAmount,
    .input-group.is-invalid .input-group-text,
    .input-group.is-invalid input:focus {
        border-color: #e21224 !important;
        color: #e21224 !important;
        .show {
            display: block !important;
        }
    }

    .input-group {
        margin-bottom: 0;
    }
    .form-control,
    .input-group .input-group-prepend .btn:first-child,
    .input-group .input-group-prepend .mm-back-link:first-child,
    .input-group .input-group-prepend .input-group-text:first-child {
        border-width: 1.5px;
    }

    hr {
        margin-bottom: 4rem;
    }

    #dispositionDateId,
    #payoutDateId,
    #payoutAmountInput {
        width: 180px;
    }

    .backbutton-drp-bond {
        padding-top: 40px;
        padding-bottom: 20px;

        .buttons {
            span {
                margin-right: 25px;
                color: #3174af;
                cursor: pointer;
            }
        }
    }
}
