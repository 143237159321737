.psr-cell {
  vertical-align: middle;
  border-top: 1px solid #c1c9d5;
  word-break: break-word;
}
.psr-cell-center {
  vertical-align: middle !important;
  border-top: 1px solid #c1c9d5 !important;
  text-align: center !important;
}
.psr-cell-no-border {
  vertical-align: middle !important;
}
.psr-cell-center-no-border {
  vertical-align: middle !important;
  text-align: center !important;
}
.psr-cell-left-no-border {
  vertical-align: middle !important;
  text-align: left !important;
}
.psr-row {
  height: 55px;
}
.psr-row-gray {
  height: 55px;
  background-color: #e9ecef;
}
.psr-chevron {
  margin-right: 20px !important;
  margin-top: 25px !important;
}
.psr-accordion {
  margin-left: 25px;
  padding-bottom: 15px;
  padding-top: 15px;
}
.psr-add-button {
  text-transform: none !important;
}
.mm-collapse {
  text-decoration: none !important;
  padding: 0 !important;
  border-top: none !important;
}
.btn-collapse {
  text-decoration: none !important;
}
.btn-collapse:focus {
  outline: none !important;
}
.psr-first-row {
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
}
