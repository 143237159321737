.reg-action-comments-form-wrapper {
    .comments-label {
        color: black;
        font-size: 16px;
        font-family: Lato;
        font-weight: 400;
        line-height: 24px;
        word-wrap: break-word;
        letter-spacing: 0.01px;
    }

    .crim-comments-container {
        width: 100%;
    }

    .backbutton-drp-crim {
        padding-top: 40px;
        padding-bottom: 20px;

        .buttons {
            span {
                margin-right: 25px;
                color: #3174af;
                cursor: pointer;
            }
        }
    }
}
